<template>
  <v-list aria-label="menu">
    <template v-for="(item, i) in menu" :key="i">
      <v-list-item
        v-if="(item.to || item.nick || item.href) && !item.children"
        :aria-label="item.label"
        role="option"
        :value="item"
        active-class="item-active"
        :active="!item.nick && (item?.to === route?.path || '/' + item?.to === route?.path)"
        v-bind="makeAttrs(item, $router)"
        v-on="makeClick(item, $router)"
      >
        {{ item.label }}
      </v-list-item>
    </template>
  </v-list>
</template>

<script setup>
/// old code variant
// <v-list aria-label="menu">
//     <template v-for="(item, i) in menu" :key="i">
//       <SmartLink
//         v-if="item.to || item.nick"
//         :to="item?.to"
//         :nick="item?.nick"
//         class="menu-item"
//         :aria-label="item.label"
//         role="option"
//       >
//         <v-list-item :value="item" :active="!item.nick && (item?.to === route?.path || '/' + item?.to === route?.path)">
//           {{ item.label }}
//         </v-list-item>
//       </SmartLink>
//     </template>
//   </v-list>

/// experiments
// <!-- <v-list aria-label="menu">
//   <template v-for="(item, i) in menu" :key="i">
//     <v-list-item
//       v-if="(item.to || item.nick) && !item.children"
//       :value="item"
//       :active="!item.nick && (item?.to === route?.path || '/' + item?.to === route?.path)"
//       v-bind="makeAttrs(item, $router)"
//       v-on="makeClick(item, $router)"
//     >
//       {{ item.label }}
//     </v-list-item> -->
// <!-- v-list-group   v-menu  v-overlay -->
// <!-- <v-list-item v-else-if="(item.to || item.nick) && item.children"> -->
// <!-- <v-list-group class="dropdown">
//         <template #activator="{ props: menuprops }">
//           <v-btn
//             height="48px"
//             color="primary"
//             v-bind="menuprops"
//             variant="text"
//             class="text-capitalize font-weight-medium text-subtitle-2 pa-4"
//           >
//             {{ item.label }}
//           </v-btn>
//         </template>
//         <template #default>
//           <v-list>
//             <v-list-item
//               v-for="(child, index) in item.children"
//               :key="index"
//               :value="index"
//               :active="!child.nick && (child?.to === route?.path || '/' + child?.to === route?.path)"
//               v-bind="makeAttrs(child, $router)"
//               v-on="makeClick(child, $router)"
//             >
//               {{ child.label }}
//             </v-list-item>
//           </v-list>
//         </template>
//       </v-list-group> -->
// <!-- </v-list-item>
//   </template>
// </v-list> -->

// import { useLead } from "@/composables/useLead";
const page = inject("page");

const props = defineProps({
  menu: { type: Array, required: false, default: () => [] },
});
// const menu = [
//   {
//     label: "111",
//     href: "https://sdfsdf/poker-hand-rankings.html",
//     holiday: "Halloween",
//     meta: {
//       hideOn: "lg",
//     },
//   },
//   {
//     label: "222",
//     to: "/222-hand-rankings",
//     disable: true,
//   },
//   {
//     label: "333",
//     to: "/333-hand-rankings",
//     meta: {
//       hideOn: "md",
//     },
//   },
//   {
//     label: "444",
//     nick: "888",
//   },
//   {
//     label: "555",
//     to: "/555.html",
//   },
//   {
//     label: "666",
//     href: "https:/test/666.html",
//   },
// ];
const { makeAttrs, makeClick } = useLead(page);
const route = useRoute();
// const activate = true;
// const items = [{ title: "Click Me" }, { title: "Click Me" }, { title: "Click Me" }, { title: "Click Me 2" }];
// const stam = async url => await navigateTo(url);
</script>
